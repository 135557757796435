<template>
  <div class="home">
    <div class="home-top">
      <div class="home-title">
        天健水务集团有限公司
      </div>
      <div class="home-content">
        天健水务，始创于2003年，是一家致力于现代化水处理设备与系统研发、生产及工程应用的国家高新技术企业。拥有陶瓷膜、次氯酸钠发生器等多项自主研发产品，业务覆盖全国20多个省份，是国内供水处理领域中石灰投加工艺、次氯酸钠发生器消毒工艺和陶瓷膜工艺的首推者。
      </div>
      <div class="home-content">
        天健水务成立20年来，在全国水务行业创造了多个第一，先后获得国家高新技术企业、国家级专精特新小巨人企业、省创新型示范中小企业、省AAA守合同重信用企业、省科技中小企业、浙江省级隐形冠军培育企业。产品被选入《全国水利系统招标产品重点采购目录》、拥有80多项国家专利及软著。
      </div>
    </div>
    <div class="home-bottom">
      <div class="bottom-title">
        自媒体导航
      </div>
      <div>
        <div v-for="(item,index) in list" :key="index" class="bottom-content" @click="jumpTo(item.jumpUrl)">
          <img :src="item.imgUrl" alt="" class="bottom-img">
          <div class="bottom-name">{{item.title}}</div>
        </div>
      </div>
    </div>
    <div class="home-bottom">
      <div class="bottom-title">
        关于我们导航
      </div>
      <div>
        <div v-for="(item1,index1) in aboutList" :key="index1">
          <div v-if="item1.jumpUrl.includes('https')" @click="jumpTo(item1.jumpUrl)" class="bottom-content1">
            <img :src="item1.imgUrl" alt="" class="bottom-img">
            <div class="bottom-name">{{item1.title}}</div>
          </div>
          <a :href="item1.jumpUrl" v-else-if="item1.jumpUrl.includes('amap')" class="bottom-content1" onclick="openBaiduMap(event)">
            <img :src="item1.imgUrl" alt="" class="bottom-img">
            <div class="bottom-name">{{item1.title}}</div>
          </a>
          <a :href="item1.jumpUrl" v-else class="bottom-content1">
            <img :src="item1.imgUrl" alt="" class="bottom-img">
            <div class="bottom-name">{{item1.title}}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data(){
    return{
      list:[
        {
          imgUrl:"https://oss.shop.tczhilian.com/static/images/douyin.png",
          title:'官方抖音',
          jumpUrl:'https://www.douyin.com/user/MS4wLjABAAAAj43Z-CzoAveVbXnO1hhr_WMzXSMwCK3jStzZkzDu8TJ_WflfLnc56RudoVizmCTj'
        },
        {
          imgUrl:"https://oss.shop.tczhilian.com/static/images/red-book.png",
          title:'官方小红书',
          jumpUrl:'https://www.xiaohongshu.com/user/profile/62c647be0000000015014f68'
        },
        {
          imgUrl:"https://oss.shop.tczhilian.com/static/images/taobao.jpg",
          title:'官方淘宝',
          jumpUrl:'https://shop307228187.taobao.com/shop/view_shop.htm?spm=a21n57.1.1.1.4afc523clqbjDl&appUid=RAzN8HWKZdTNrfdzJQ2QGV4su94WfSS2Nbx4MxBymQ16hg5ss87'
        },
        {
          imgUrl:"https://oss.shop.tczhilian.com/static/images/gongzhong.jpg",
          title:'官方公众号',
          jumpUrl:'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA4MzQwNDE3OA==&amp&scene=110#wechat_redirect'
        },
      ],
      aboutList:[
        {
          imgUrl:"https://oss.shop.tczhilian.com/static/images/web.png",
          title:'官方网站',
          jumpUrl:'https://www.zjtxjsw.com/'
        },
        // {
        //   imgUrl:"https://pic.imgdb.cn/item/66c83aadd9c307b7e92bbf8d.png",
        //   title:'企业位置',
        //   jumpUrl:'https://www.xiaohongshu.com/user/profile/62c647be0000000015014f68'
        // },
        {
          imgUrl:"https://oss.shop.tczhilian.com/static/images/call-phone.png",
          title:'企业电话',
          jumpUrl:'tel:4000-03-09-28'
        },
        {
          imgUrl:"https://oss.shop.tczhilian.com/static/images/youxiang.png",
          title:'企业邮箱',
          jumpUrl:'mailto:guo.mx@tjian.com'
        },
        // {
        //   imgUrl:"https://oss.shop.tczhilian.com/static/images/location.png",
        //   title:'企业位置',
        //   jumpUrl:'https://uri.amap.com/navigation?to=30.274067,120.206731,浙江省杭州市上城区衍印影视文化中心&mode=car&policy=0&src=mypage&coordinate=gaode&callnative=1'
          
          
        //   // https://uri.amap.com/marker?position=30.274067,120.206731
        //   // http://map.baidu.com/?new=1&lat=30.274067&lng=120.206731
        //   // https://api.map.baidu.com/direction?origin=30.274067,120.206731&destination=30.274067,120.206731&mode=driving&region=杭州&output=html
        // }
      ]
    }
  },
  methods:{
    jumpTo(url){
      window.location.href = url
    },
    openBaiduMap(e){
      window.location.href = e.target.href;
    }
  }
}
</script>

<style scoped lang="scss">
.home{
  width: 100vw;
  background-image: linear-gradient(to bottom right,#4481eb,#04befe);
  padding: 30px 25px;
  box-sizing: border-box;
  .home-top{
    .home-title{
      font-size: 22px;
      color: #fff;
      text-align: left;
      margin-bottom: 20px;
    }
    .home-content{
      color: rgba(255,255,255,.9);
      font-size: 14px;
      text-indent: 2em;
      text-align: left;
      line-height: 20px;
    }
  }
  .home-bottom{
    // width: 100vw;
    margin-top: 22px;
    background: #fff;
    border-radius: 20px;
    padding: 15px 0;
    box-sizing: border-box;
    .bottom-title{
      text-align: left;
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: 600;
    }
    .bottom-content{
      display: flex;
      align-items: center;
      padding: 15px;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;
      text-decoration: none;
      color: inherit;
      .bottom-img{
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 5px;
        overflow: hidden;
      }
      .bottom-name{
        font-size: 14px;
      }
    }
    .bottom-content1{
      display: flex;
      align-items: center;
      padding: 15px;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;
      text-decoration: none;
      color: inherit;
      .bottom-img{
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 5px;
        overflow: hidden;
      }
      .bottom-name{
        font-size: 14px;
      }
    }
    
  }
  .bottom-content:last-child{
    border: none;
    padding: 15px 15px 0;
  }
}
</style>